/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "normalize.css";
import './src/styles/styles.scss';

import React from 'react';
import { UserContextProvider } from './src/context/UserContext';

export const wrapRootElement = ({ element }) => {
  return (
    <UserContextProvider>
      {element}
    </UserContextProvider>
  );
};
