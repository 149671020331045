// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-profiles-index-js": () => import("./../../../src/pages/career-profiles/index.js" /* webpackChunkName: "component---src-pages-career-profiles-index-js" */),
  "component---src-pages-career-profiles-qa-with-data-experts-js": () => import("./../../../src/pages/career-profiles/qa-with-data-experts.js" /* webpackChunkName: "component---src-pages-career-profiles-qa-with-data-experts-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-discover-data-privacy-js": () => import("./../../../src/pages/discover-data-privacy.js" /* webpackChunkName: "component---src-pages-discover-data-privacy-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-master-class-js": () => import("./../../../src/pages/master-class.js" /* webpackChunkName: "component---src-pages-master-class-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */),
  "component---src-templates-career-profile-js": () => import("./../../../src/templates/careerProfile.js" /* webpackChunkName: "component---src-templates-career-profile-js" */)
}

