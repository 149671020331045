import React, { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * Per the React docs, this is ONLY used when an ancestor provider is not found.
 */
const defaultState = {
  loggedIn: false,
  first_name: 'anonymous',
  checkAgainCounter: 0,
  finishedInitialCheck: false,
  setLoggedIn: () => {},
  setFirstName: () => {},
  checkAgain: () => {},
};

/**
 * The actual context singleton.
 * You can import this to use the 'useContext' hook with a parent provider.
 */
const UserContext = React.createContext(defaultState);

/**
 * The standard context provider you can use to wrap around children.
 * Children of this node can use the 'useContext' React hook to access the value.
 */
const UserContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState();
  const [checkAgainCounter, setCheckAgainCounter] = useState(0);
  const [finishedInitialCheck, setFinishedInitialCheck] = useState(false);
  const [providerValue, setProviderValue] = useState({
    loggedIn,
    firstName,
    checkAgainCounter,
    finishedInitialCheck,
    setLoggedIn,
    setFirstName,
    checkAgain: () => setCheckAgainCounter(checkAgainCounter + 1),
  });

  // Check on componentDidMount OR checkAgainCounter is incremented.
  useEffect(() => {
    // Since oauth session session cookie is not available or set, call the backend API
    axios
      .get('/api/user-status?_format=json')
      .then((response) => {
        setLoggedIn(response.data['is_logged_in'] === true);
        setFirstName(response.data['first_name'] || null);
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() => {
        setFinishedInitialCheck(true);
      });
  }, [checkAgainCounter]);

  // Update UserContext Value
  useEffect(() => {
    setProviderValue({
      loggedIn,
      firstName,
      checkAgainCounter,
      finishedInitialCheck,
      setLoggedIn,
      setFirstName,
      checkAgain: () => setCheckAgainCounter(checkAgainCounter + 1),
    });
  }, [loggedIn, firstName, finishedInitialCheck, checkAgainCounter]);

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserContextProvider };
